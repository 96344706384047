import styled from "styled-components"
import { device } from "breakpoints"
import quoteMark from "icons/avenue/quotation-marks.svg"

export const ContentWrapper = styled.div`

> *:first-child {
  margin-top:0 !important;
}
> * {
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  max-width:${(props) => props.fullWidth && "100%"};
} 

  .wp-block-columns {

    @media ${device.desktop} {
      display: flex;
      gap: 40px;
    }

    .wp-block-column {
      flex: 1;
    }
  }

  div.wp-block-buttons{
    margin:30px auto;
    @media ${device.desktop} {
      margin:36px auto;
    }
  }
  
  a.wp-block-button__link {
    border-radius: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 23px;
    background: #FD8211;
    font-size: 16px;
    color: white;
    font-weight: bold;
    align-self: flex-start;
    justify-self: flex-start;
    transition: 120ms;

    @media ${device.desktop} {
      font-size: 20px;
      padding: 15px 26px;
    }

    :hover {
      background: #de720e;
    }
  }


  .wp-block-columns h2, .wp-block-columns h3, .wp-block-columns h4, .wp-block-columns h5, .wp-block-columns h6,
  >h1,
  >h2,
  >h3,
  >h4,
  >h5,
  >h6 {
      margin-top: 15px;
    margin-bottom: 10px;

    @media ${device.desktop} {
      margin-top: 20px;
      margin-bottom: 17px;
    }
  }



  >h1, >h2 {
    font-size: 28px;
    line-height: 1.25;
    @media ${device.desktop} {
      font-size: 36px;
    }
  }

  >h3 {
    font-size: 24px;
    line-height: 1.25;
    @media ${device.desktop} {
      font-size: 32px;
    }
  }

  >h4 {
    font-size: 18px;
    line-height: 1.25;
    @media ${device.desktop} {
      font-size: 28px;
    }
  }

  >h5 {
    font-size: 16px;
    line-height: 1.5;
    @media ${device.desktop} {
      font-size: 24px;
    }
  }

  
  .wp-block-columns p, .wp-block-columns li,
  > p, li {
    font-size: 16px;
    @media ${device.desktop} {
      font-size: 18px;
    }

    a {
      display: inline-block;
      position: relative;
      color:#008CD1;
      font-weight: 600;
      background-image: linear-gradient(to right, #47C5F3, #47C5F3);
      background-position: 0 93%;
      background-repeat: no-repeat;
      background-size: 100% 0px;

      :hover {
        background-size: 100% 2px;
      }
    }
  }
  
  .wp-block-columns p {
    margin-bottom: 20px;
    @media ${device.desktop} {
      margin-bottom: 28px;
    }
  }

  > p {
    margin-bottom: 20px;
    @media ${device.desktop} {
      margin-bottom: 28px;
    }


    ${(props) =>
      !props.noIntroStyles &&
      `
    
    &:first-of-type {
      font-size:20px;
      font-family: ${(props) => props.theme.headerFont};
      font-weight:600;
      color: #1870B0;
      line-height:125%;
      @media ${device.desktop} {
      font-size:24px;
      }
    }
    
    
    `};

 

  }

  /* generic list styles */
  .wp-block-columns ul,
  > ul, > ol {
    list-style: none;
    padding-left: 25px;
    margin-bottom: 20px;

    @media ${device.desktop} {
      margin-bottom: 35px;
    }

    >li {
      margin-bottom:14px;
    }
  }

  /* unordered list styles */
  .wp-block-columns ul,
  > ul {
    padding-left: 25px;
    
    > li {
    position: relative;
      ::before {
        content: "";
        height: 8px;
        width: 8px;
        background-color: #47C5F3;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 7px;
        left: -25px;

        @media ${device.desktop} {
        top: 11px;
        }
      }
    }
  }


  /* ordered list styles */

  > ol {
    counter-reset:counter;
    padding-left:25px;
    @media ${device.desktop} {
      padding-left: 30px;
    }
    > li {
    counter-increment: counter;
    position: relative;
      ::before {
        content: counter(counter);
        position: absolute;
        left: -25px;
        font-family: ${(props) => props.theme.headerFont};
        top: 0;
        font-size:16px;
        font-weight: bold;
        color:  #008CD1;

        @media ${device.desktop} {
          font-size:20px;
          left: -30px;
          top: -1px;


        }
      }
    }
  }


  figure {
    width: 100%;
    margin: 0 auto;
  }


  /* content image styles */

  .wp-block-columns figure.wp-block-image {
    margin: 0;
  }

  .wp-block-image.aligncenter img {
      margin: 0 auto;
  }

  figure.wp-block-image {
    margin-top: 30px;
    margin-bottom: 27px;
    position:relative;

    @media ${device.desktop} {
      margin-bottom: 70px;
      margin-top: 54px;
    }

    img {
      width: 100%;
      display: block;
      border-radius:4px;
      object-fit: cover;
      height: auto !important;
    }

    &.alignfull {
      width: 100vw;
      max-width:100vw;
      margin-left:-20px;

        img {
          border-radius:0px;
        }

      @media ${device.desktop} {
        margin-left:0px;
        width:100%;
        max-width:1160px;
        img {
          border-radius:4px;
        }
      }
    }

    figcaption {
      margin-top:10px;
      color:white;
      font-weight:bold;
      font-size:12px;
      position:absolute;
      bottom:15px;
      left:20px;
      padding-top:10px;
      border-top:1px solid white;
      width:calc(100% - 40px);
      z-index:2;
      @media ${device.tablet} {
      font-size:16px;
      bottom:20px;
      padding-top:15px;
      }
    }

    //shadow gradient overlay
    :has(figcaption):before {
      content:'';
      z-index:1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 77.4%, rgba(0, 0, 0, 0.45) 94.96%);
      position:absolute;
      top:0;
      left:0;
      width: 100%;
      height:100%;

      @media ${device.desktop} {
        border-radius:4px;
      }
    }
  }

  /* blockquote styles */

  > blockquote {
    margin: 30px auto;
    position: relative;
    padding-left: 30px;
    padding-top:50px;

    @media ${device.desktop} {
    padding-top:73px;
    padding-left: 60px;
    margin: 60px auto;

    }

    &:after {
      content:"";
      left:0;
      top:0;
      position:absolute;
      background:#008CD1;
      height:calc(100% - 4px);
      width:5px;
      border-radius:4px;
    }

    :before {
      content: "";
      position: absolute;
      width: 45px;
      height: 36px;
      background-image: url('${quoteMark}');
      background-size: 100%;
      background-repeat: no-repeat;
      display: block;
      top: 0;
      left: 30px;
      
     
      @media ${device.desktop} {
        width: 68px;
        height: 54px;
        left: 60px;

      }
    }

    p {
      font-size: 20px;
      margin-left: 0;
      font-weight:500;
      font-family: ${(props) => props.theme.headerFont};
      color:#303030;
      line-height:125%;

      @media ${device.desktop} {
        font-size: 24px;

        line-height:150%;

    }
      
    }
     &.is-style-quote--simple {
      padding-top:0;
      :before {
        display:none;
      }
      p {
        font-weight:300;
      }}


    cite {
      font-size: 12px;
      color: #008CD1;
      margin-top:15px;
      display:block;
      position: relative;
      font-style: normal;
      font-weight:bold;

      @media ${device.desktop} {
        margin-top:26px;
        font-size:14px;
      }
  
    }
  }
  
  /* video embed styles */
  figure.is-type-video {
    margin: 30px auto;

    @media ${device.desktop} {
      margin: 60px auto;
    
    }
    .wp-block-embed__wrapper {
    border-radius:4px;

      position: relative;
      padding-bottom: 60%;

      iframe {
        position: absolute;
      border-radius:4px;

        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
`
