import React from "react"
import { Link as GatsbyLink } from "gatsby"

const AvenueLink = ({
  children,
  to,
  target,
  activeClassName,
  partiallyActive,
  ...other
}) => {
  const internal = /^[/#](?!\/)/.test(to)
  const email = to.includes("mailto:")

  const checkSite = (site, email) => {
    const prefix1 = "http://"
    if (
      site &&
      site.substr(0, prefix1.length) !== prefix1 &&
      (site.includes("www.") || site.includes(".com")) &&
      !site.includes("https") &&
      !email
    ) {
      return prefix1 + site
    } else {
      return site
    }
  }

  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a
      target={target ? target : email ? null : `_blank`}
      rel="noopener noreferrer"
      href={checkSite(to, email)}
      {...other}
    >
      {children}
    </a>
  )
}

export default AvenueLink
