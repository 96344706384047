import styled from "styled-components"
import { device } from "breakpoints"
import { Button } from "../Button/index.js"

export const PopUpContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999101;
  display: ${(props) => (props.isPopUpOpen ? "block" : "none")};
  transition: 80ms;
  pointer-events: ${(props) => (props.isPopUpOpen ? "all" : "none")};
`

export const PopUpBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.2;
`

export const ClosePopUpButton = styled(Button)`
  position: absolute;
  top: -55px;
  right: 25px;
  z-index: 11;
  background: none;
  padding: 0;
  margin: 0;

  :hover {
    background: none;
    svg {
      rect {
        fill: #008cd1;
      }
    }
  }

  @media ${device.desktop} {
    top: 6px;
    right: -30px;
  }
`

export const PopUpMain = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  height: 50%;
  width: 50%;
  background: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: calc(100% - 40px);
  height: auto;
  max-width: 697px;

  a:hover {
    text-decoration: underline;
  }

  @media ${device.desktop} {
    width: 697px;
    height: auto;
  }
`

export const ButtonNo = styled.button`
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
  cursor: pointer;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`
