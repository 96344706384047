import React, { useEffect } from "react"
import PropTypes from "prop-types"
import "./layout.css"
import theme from "../utils/theme.js"
import { ThemeProvider } from "styled-components"
import { Header } from "UI/Header"
import { FooterCTA } from "components/Project/FooterCTA"
import { Footer } from "UI/Footer"
import { NavBar } from "./UI/NavBar"
import ActiveCampaignTracker from "./ActiveCampaignTracker"
import { GDPRBanner } from "UI/GDPRBanner"

import { Helmet } from "react-helmet"

const Layout = ({ variant, children, pageContext }) => {
  console.log(pageContext)
  return (
    <ThemeProvider theme={theme}>
      <GDPRBanner pageContext={pageContext} />

      <Helmet>
        {/* Inspectlet */}
        <script
          type="text/javascript"
          data-avenue={`activecampaign-${pageContext.uri}`}
          dangerouslySetInnerHTML={{
            __html: `
         
              console.log('activecampaign site tracking: ${pageContext.uri}');
            `,
          }}
        />
      </Helmet>

      <div className="app-wrapper">
        <ActiveCampaignTracker pageContext={pageContext} />
        <NavBar />
        <Header variant={variant} pageContext={pageContext} />
        <main>{children}</main>
        <FooterCTA />
        <Footer />
      </div>
      <script
        type="text/javascript"
        async
        src="//l.getsitecontrol.com/v7nvnk9w.js"
      ></script>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
