import React, { useState, useRef, useEffect } from "react"
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
import { Box, Text, Button } from "UI"
import { PopUpContainer, PopUpBackground, PopUpMain, ButtonNo } from "./style"

import TagManager from "react-gtm-module"

const GDPRBanner = ({ children, pageContext }) => {
  const [isPopUpOpen, setPopUpOpen] = useState(false)

  const popUpRef = useRef(null)

  const closePopUp = () => {
    setPopUpOpen(false)
  }

  const initTracking = () => {
    // Only initialize TagManager once when component mounts if consent is yes

    if (!window.initTracking) {
      const tagManagerArgs = {
        gtmId: "GTM-NKGLVFZW",
      }
      TagManager.initialize(tagManagerArgs)
      window.initTracking = true

      console.log("init Tracking")
    }
  }

  const showPopup = () => {
    if (pageContext.uri === "/privacy-policy/") {
      setPopUpOpen(false)
    }

    if (
      pageContext.uri !== "/privacy-policy/" &&
      !localStorage.getItem("cookieConsent")
    ) {
      setPopUpOpen(true)
    }
  }

  useEffect(() => {
    const targetElement = popUpRef.current

    if (isPopUpOpen) {
      disableBodyScroll(targetElement)
    } else {
      clearAllBodyScrollLocks()
    }
  }, [isPopUpOpen])

  useEffect(() => {
    showPopup()

    // init tracking
    if (localStorage.getItem("cookieConsent") === "yes") {
      initTracking()
    }
  }, [])

  return (
    <PopUpContainer isPopUpOpen={isPopUpOpen}>
      <PopUpBackground />

      <PopUpMain ref={popUpRef}>
        <Box position="relative" width="100%" p={32}>
          <Text as="h3" fontSize={[20, null, 24]} mb={24} textAlign="left">
            We value your privacy
          </Text>
          <Text as="p" textAlign="left">
            We use cookies for analytics and tracking to enhance your experience
            on our site. By continuing to browse, you consent to our use of
            cookies. Learn more by reading our{" "}
            <a
              href="/privacy-policy"
              style={{
                color: " #008CD1",
                fontWeight: "bold",
              }}
            >
              Privacy Policy
            </a>
            .
          </Text>
        </Box>

        <Box
          width="100%"
          flexDirection={["column", "row"]}
          justifyContent={[null, "space-between"]}
          p={[24, 32]}
          borderTop="1px solid #CED0D2"
        >
          {" "}
          <Box pt={[null, 14]}>
            <ButtonNo
              onClick={() => {
                localStorage.setItem("cookieConsent", "no")
                closePopUp()
              }}
            >
              Continue without accepting
            </ButtonNo>
          </Box>
          <Button
            width="160px"
            onClick={() => {
              localStorage.setItem("cookieConsent", "yes")
              window.vgo("process", "allowTracking")
              closePopUp()
              initTracking(true)
            }}
            alignSelf={["center", "flex-start"]}
          >
            Accept
          </Button>
        </Box>
      </PopUpMain>
    </PopUpContainer>
  )
}

export default GDPRBanner
